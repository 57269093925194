@font-face {
  font-family: 'Proxima Nova';
  font-weight: bold;
  src: url('./assets/fonts/proximanova-bold.otf') format('opentype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 300;
  src: url('./assets/fonts/proximanova-light.otf') format('opentype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: normal;
  src: url('./assets/fonts/ProximaNova-Regular.otf') format('opentype');
}

body,
html,
#root {
  min-height: 100vh;
  min-width: 320px;
}

body {
  background: #1d252c;
}

.app {
  align-items: center;
  color: #fcfcfc;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  padding: 0 24px;
  text-align: center;
}

.contact-us {
  margin: 48px 0;
  max-width: 300px;
}

.subtitle {
  margin: 0 0 48px 0;
  max-width: 360px;
}

.text-default {
  font-family: 'Proxima Nova', sans-serif;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.75;
}

.text-large {
  font-family: 'Proxima Nova', sans-serif;
  font-size: 1.25em;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 1.4;
}

.title {
  font-family: 'Proxima Nova', sans-serif;
  font-size: 2em;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.125;
  margin: 48px 0 24px 0;
  max-width: 480px;
}
